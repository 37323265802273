const applyRecaptcha = (e) => {
    e.preventDefault();
    console.log('recaptcha execution');
    grecaptcha.ready(function () {
        //GETTING SITE KEY FROM FORM INPUT
        const siteKey = document.getElementById('recaptcha_site_key').value;
        console.log(siteKey);
        if (siteKey){
            grecaptcha.execute(siteKey, {action: 'submit'}).then(function (token) {
                const recaptchaForm = document.getElementById('recaptcha');
                if (recaptchaForm) {
                    //INPUT WITH RECAPTCHA TOKEN FOR BACKEND VALIDATION
                    let hiddenInput = document.createElement('input');
                    hiddenInput.type = 'hidden';
                    hiddenInput.name = 'recaptcha_token';
                    hiddenInput.value = token;
                    recaptchaForm.insertAdjacentElement('beforeend', hiddenInput);
                    recaptchaForm.submit();
                }
            });
        }
    });
};

const recaptchaForm = document.getElementById('recaptcha');
if (recaptchaForm) {
    recaptchaForm.addEventListener('submit', applyRecaptcha);
}

